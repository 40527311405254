import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

const getDateFormatted = (date) => `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

function NewBlock(props) {
    const {
        date, title, uri, currentNodeIndex, totalNodesAmount, isArticlePage,
    } = props;
    const lastBlock = currentNodeIndex === totalNodesAmount - 1;
    return (
        <Grid item xs={12} className={`${(!lastBlock && 'noLastblock') || ''}`}>
            {!isArticlePage && (
                <span className="dateSpan">{getDateFormatted(new Date(date))}</span>
            )}
            <a href={uri} rel="noopener noreferrer" className="newsLink">
                {title}
            </a>
        </Grid>
    );
}

NewBlock.propTypes = {
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isArticlePage: PropTypes.bool.isRequired,
    uri: PropTypes.string.isRequired,
    currentNodeIndex: PropTypes.number.isRequired,
    totalNodesAmount: PropTypes.number.isRequired,
};

function NewsBar({ isArticlePage }) {
    return (
        <>
            <h3 className="sidebarHeader">
                <span>The Latest News</span>
            </h3>
            <div className="block">
                <StaticQuery
                    query={graphql`
        query {
          allWpPost(
          limit: 9
          filter: { nodeType: { eq: "Post"} }
          sort: { fields: date, order: DESC }
          ) {
            nodes {
              uri
              title
              date
              id
            }
          }
          wpPage(id: { eq: "cG9zdDo3MzY2"}) {
            content
          }
        }
      `}
                    render={(data) => (
                        <>
                            {data.allWpPost.nodes.map((node, index) => (
                                <NewBlock
                                    key={`${node.id}`}
                                    date={node.date}
                                    title={node.title}
                                    isArticlePage={isArticlePage}
                                    uri={node.uri}
                                    currentNodeIndex={index}
                                    totalNodesAmount={data.allWpPost.nodes.length}
                                />
                            ))}
                        </>
                    )}
                />
            </div>
        </>
    );
}

export default NewsBar;