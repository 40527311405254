import React from 'react';
import { graphql } from 'gatsby';
import { instanceOf } from 'prop-types';
import { Grid } from '@material-ui/core';
import Layout from '../components/layout';
import ArticleGrid from '../components/template-parts/ArticleGrid';
import '../assets/app.css';
import SideBar from '../components/NewsBar';

const HomePage = ({ data: { allWpPost }, pageContext }) => {
  return (
    <Layout>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid className="postContainer" item xs={12} sm={9}>
          <ArticleGrid
            listTitle="Top Stories"
            maxColumns="4"
            posts={allWpPost.nodes}
            pageContext={pageContext}
          />
        </Grid>
        <Grid item xs={12} sm={3} className="sidebarGrid">
          <SideBar postLimit={9} />
        </Grid>
      </Grid>
    </Layout>
  );
};

HomePage.propTypes = {
  data: instanceOf(Object).isRequired,
  pageContext: instanceOf(Object).isRequired,
};

export default HomePage;

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query HomePage($offset: Int!, $perPage: Int!) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: { nodeType: { in: ["Post", "Page", "Alot"] } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        uri
        title
        nodeType
        categories {
          nodes {
            name
          }
         }
        featuredImage {
          node {
              localFile {
              ...Thumbnail
            }
          }
        }
      }
    }
  }
`;
